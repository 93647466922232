document.addEventListener("alpine:init", () => {
	Alpine.data("progressBarComponent", (numerator, denominator) => ({
		numerator,
		denominator,

		percentage: function () {
			if (this.denominator === 0) {
				return "0%" // This shouldn't happen, but we don't want to break other Javascript
			}

			let perc = this.numerator / this.denominator
			return parseInt(Math.round(perc * 100)) + "%"
		},

		fraction: function () {
			return this.numerator + "/" + this.denominator
		},
	}))
})
